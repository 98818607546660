import startCase from 'lodash/startCase';
import { useMemo, useState } from 'react';
import { toHTML } from 'slack-markdown';

import { type Media } from '@lp-lib/media';

import config from '../../config';
import {
  type CelebrationEventMessageSettings,
  type CelebrationEventType,
  type CelebrationMessageSettings,
} from '../../types/celebration';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { MediaPreview } from '../MediaUploader/MediaPreview';
import { CelebrationEventKnifeUtils } from './CelebrationEventUtils';

export function SlackMessagePreview(props: {
  text: string;
  media: Media | null;
  className?: string;
}): JSX.Element {
  const previewHTML = useMemo(() => {
    return toHTML(props.text, { escapeHTML: false });
  }, [props.text]);

  return (
    <div
      className={`w-full p-4 pr-7.5 border border-secondary rounded-xl ${props.className}`}
    >
      <div className='flex flex-row items-center gap-2'>
        <img
          src={config.slack.botIconURL}
          alt='luna park'
          className='w-6 h-6 border border-[#2E2E2E]'
        />
        <h3 className='font-bold text-sms'>{config.slack.botUsername}</h3>
      </div>

      <div className='mt-1 w-full pl-8 text-sms'>
        <div
          className='message-template-preview'
          dangerouslySetInnerHTML={{
            __html: previewHTML,
          }}
        ></div>

        <hr className='mt-2 mb-5 border-secondary' />

        <a
          href={`/home?tag=${config.misc.celebrationsTagSlug || ''}`}
          target='_blank'
          className='text-primary'
          rel='noreferrer'
        >
          Click here to celebrate on Luna Park
        </a>

        {props.media && (
          <div className='mt-5'>
            <MediaPreview media={props.media} />
          </div>
        )}
      </div>
    </div>
  );
}

export function CelebrationMessagePreview(props: {
  type: CelebrationEventType;
  messageSettings?: CelebrationMessageSettings;
  settings?: CelebrationEventMessageSettings;
  className?: string;
}): JSX.Element {
  const { type, messageSettings, settings, className } = props;

  const { text, media } = CelebrationEventKnifeUtils.Message(
    type,
    messageSettings,
    settings
  );

  return (
    <SlackMessagePreview text={text} media={media} className={className} />
  );
}

export function CelebrationMessageEditModal(props: {
  type: CelebrationEventType;
  messageSettings: CelebrationMessageSettings;
  settings: CelebrationEventMessageSettings;
  onCancel: () => void;
  onSave: (value: string) => void;
}): JSX.Element {
  const { type, messageSettings, settings, onCancel, onSave } = props;

  const label = CelebrationEventKnifeUtils.Label(type);

  const [message, setMessage] = useState(settings.customizedMessage);

  return (
    <ModalWrapper containerClassName='w-288' borderStyle='gray'>
      <div className='w-full px-14 py-9'>
        <header className='text-2xl font-medium'>
          Customize Your {startCase(label)} Message
        </header>
        <main className='mt-5 w-full flex gap-9'>
          <div className='w-1/2'>
            <textarea
              className={`field h-100 scrollbar p-4 pr-7.5 bg-secondary`}
              value={message}
              onChange={(e) => setMessage(e.currentTarget.value)}
              maxLength={1000}
            />
            <div className='my-px text-icon-gray text-3xs'>
              <span className='font-bold mx-1'>*Bold*</span>
              <span className='italic mx-1'>_italics_</span>
              <span className='mx-1'>~strike~</span>
              <span className='mx-1'>&gt; quote</span>
              <span className='mx-1'>&lt;https://www.link.com|link&gt;</span>
              {/* <span className='mx-1'>@user</span> */}
              <span className='mx-1'>:emoji:</span>
            </div>
          </div>
          <div className='w-1/2'>
            <CelebrationMessagePreview
              type={type}
              messageSettings={messageSettings}
              settings={{
                ...settings,
                customizedMessage: message,
              }}
              className='h-100 overflow-auto'
            />
          </div>
        </main>
        <footer className='mt-6 flex justify-between items-center'>
          <div className='flex items-center gap-16'>
            {message !== settings.customizedMessage && (
              <button
                type='button'
                className='btn text-base font-medium'
                onClick={() => setMessage(settings.customizedMessage)}
              >
                Reset to Default
              </button>
            )}
          </div>
          <div className='flex items-center gap-16'>
            <button type='button' className='btn' onClick={onCancel}>
              Cancel
            </button>
            <button
              type='button'
              className='btn-primary w-60 h-10'
              onClick={() => onSave(message)}
            >
              Save Custom Message
            </button>
          </div>
        </footer>
      </div>
    </ModalWrapper>
  );
}
